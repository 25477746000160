<template>
	<div class="securityformsFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md12>
				<v-row dense>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.visitid')" :value="getPortcallNumber"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.mships.nameofship')" :value="getShipName"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.securityforms.shiptype')" :value="getTypeShip"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.imo')" :value="getShipIMO"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.callsign')" :value="getCallSing"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.flagdesc')" :value="getFlag"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.portofcall')" :value="getPort"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="$t('header.status')" :value="getStatusdescriptionValue"></pui-field>
					</v-col>
				</v-row>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'securityforms-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'securityforms'
		};
	},
	computed: {
		getPortcallNumber() {
			return this.model && this.model.visitid ? this.model.visitid : '-';
		},
		getShipName() {
			return this.model && this.model.shipname ? this.model.shipname : '-';
		},
		getTypeShip() {
			return this.model && this.model.shiptypedesc ? this.model.shiptypedesc : '-';
		},
		getShipIMO() {
			return this.model && this.model.imo ? this.model.imo : '-';
		},
		getCallSing() {
			return this.model && this.model.callsign ? this.model.callsign : '-';
		},
		getFlag() {
			return this.model && this.model.countrydesc ? this.model.countrydesc : '-';
		},
		getPort() {
			return this.model && this.model.portdescription ? this.model.portdescription : '-';
		},
		getStatusdescriptionValue() {
			return this.model && this.model.statusdescription ? this.model.statusdescription : '-';
		}
	}
};
</script>
